const ScrollMagic = require("scrollmagic");
var rellax = new Rellax(".rellax");

$(document).ready(function() {
  var controller = new ScrollMagic.Controller();

  var resume = new ScrollMagic.Scene({
    triggerElement: "#loco"
  })
    .setClassToggle("#loco", "fade-in")
    .addTo(controller);

  var sb = new ScrollMagic.Scene({
    triggerElement: "#starbucks"
  })
    .setClassToggle("#starbucks", "fade-in")
    .addTo(controller);

  var kroger = new ScrollMagic.Scene({
    triggerElement: "#kroger"
  })
    .setClassToggle("#kroger", "fade-in")
    .addTo(controller);

  var accordion = new ScrollMagic.Scene({
    triggerElement: "#accordion"
  })
    .setClassToggle("#accordion", "fade-in")
    .addTo(controller);

  var apex = new ScrollMagic.Scene({
    triggerElement: "#apex"
  })
    .setClassToggle("#apex", "fade-in")
    .addTo(controller);

  var scroll = new ScrollMagic.Scene({
    triggerElement: "#apex"
  })
    .setClassToggle("#scroll", "display-none")
    .addTo(controller);
});
